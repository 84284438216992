<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-22 17:17:25
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div>
    <page-top :title="$t('supplier_order.E0C2CB@order_management')">
      <span class="fa fa-list-alt fa-2x"></span>
    </page-top>
    <el-card class="box-card">
      <el-form :inline="true" :model="search">
        <el-form-item>
          <el-date-picker
              v-model="search.datetime"
              style="width:100%;"
              type="daterange"
              value-format="timestamp"
              @change="changeDatetime"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
              v-model="search.status_no"
              @change="changeByEvent"
              :placeholder="$t('supplier_order.E5F50A@order_status')"
              clearable
          >
            <el-option :value="1" :label="$t('public.64203E@to_be_determined')"></el-option>
            <el-option :value="2" :label="$t('orderDetail.EB17E9@Refuse_order')"></el-option>
            <el-option :value="3" :label="$t('orderDetail.73FE8F@Cancel_order')"></el-option>
            <el-option :value="4" :label="$t('public.471669@Confirmed_order')"></el-option>
            <el-option :value="5" :label="$t('public.8E9DAD@Some_accept')"></el-option>
            <el-option :value="6" :label="$t('orderDetail.7879F8@have_received')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
              v-model="search.order_no"
              @blur="changeByEvent"
              :placeholder="$t('supplier_order.46C89C@order_reference')"
              @clear="clear"
              clearable
          >
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
              filterable
              v-model="search.shop_id"
              @change="changeByEvent"
              :placeholder="$t('supplier_order.066E3C@shop')"
              @clear="clear"
              clearable
          >
            <el-option
                v-for="item in storeOption"
                :key="item.shop_id"
                :value="item.shop_id"
                :label="item.shop_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
              v-model="search.pay_status"
              @change="changeByEvent"
              :placeholder="$t('public.180B5F@Payment_status')"
              @clear="clear"
              clearable
          >
            <el-option :label="$t('aboutus.323AC0@Awaiting_Payment')" :value="1"></el-option>
            <el-option :label="$t('aboutus.C7977E@Payment_Receieved')" :value="2"></el-option>
            <el-option :label="$t('aboutus.245620@Overdue')" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="showDialog=true">{{ $t('public.E123AEF@add_order') }}</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table
          border
          :data="tableData"
          :highlight-current-row="true"
          :row-class-name="$tableRowClassName"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{'text-align':'center',}"
          :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="order_no" :label="$t('supplier_order.46C89C@order_reference')"></el-table-column>
        <el-table-column
            prop="restaurant_name"
            :label="$t('backend_authrity_authrityList.C90CD7@restaurant_tab')"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="buyer_shop_name" :label="$t('orderDetail.2D64A4@store_name')"></el-table-column>
        <!-- <el-table-column
          prop="user_name"
          :label="$t('restaurant_buy_genner.E3B214@place_order_user')"
        ></el-table-column>-->
        <el-table-column prop="final_price" :label="$t('public.C82510@order_amount')">
          <template scope="scope">
            <span v-if="scope.row.final_price">{{ scope.row.final_price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_status" :label="$t('supplier_order.E5F50A@order_status')">
          <template scope="scope">
            <span :style="scope.row.order_color|sizeColor">{{ scope.row.order_status }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_status" :label="$t('public.180B5F@Payment_status')">
          <template scope="scope">
            <section :style="scope.row.pay_colour|sizeColor">
              <div v-if="scope.row.order_color==1 && scope.row.pay_colour==1">
                {{ `${scope.row.pay_status}(${scope.row.pay_method})` }}
              </div>
              <div v-else>{{ scope.row.pay_status }}</div>
              <div v-show="scope.row.payment_due">({{ scope.row.payment_due }})</div>
            </section>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :label="$t('public.D17BCA@created_time')"></el-table-column>
        <el-table-column prop="option" :label="$t('public.03EA09@operation')" fixed="right">
          <template slot-scope="scope">
            <KtButton
                type="warning"
                :label="$t('public.653358@view')"
                :perm="2017"
                @click="rowClick(scope.row.order_no)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>
      <section class="flex-end mt20">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5,10,15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <!--  新增訂單  -->
    <el-dialog :visible.sync="showDialog" width="90vw" class="AddOrder"
               :title="$t('public.E123AEF@add_order')" center>
      <el-row>
        <el-col :span="24">
          <div class="header">
          </div>
        </el-col>
      </el-row>
      <div class="delivery">
        <el-row>
          <el-col :span="8">
            <!--     餐廳       -->
            {{ $t('aboutus.033E98@Please_Select_Restaurant') }}&nbsp;:&nbsp;&nbsp;
            <el-select v-model="order.rid" clearable filterable @change="restaurantChange"
                       style="padding-bottom: 10px;width: 250px">
              <el-option v-for="item in restaurantList" :label="item.company_name" :key="item.company_id"
                         :value="item.company_id"></el-option>
            </el-select>
            <br>
            <!--     分店       -->
            {{ $t('backend_authrity_grouping.422561@edit_store') }}&nbsp;:&nbsp;&nbsp;
            <el-select v-model="order.sid" clearable filterable @change="shopChange" style="width: 250px">
              <el-option v-for="item in shopList.shop" :label="item.shop_name" :key="item.shop_name"
                         :value="item.shop_id"></el-option>
            </el-select>
            <!--     供應商資訊欄       -->
            <section v-if="order.shop" class="outlet">
              <!--     付款方式       -->
              <span>
                {{ $t("aboutus.F31309@fee_ways") }}&nbsp;:&nbsp;
                <el-select style="text-align: center !important;" v-model="order.pay_method">
                  <el-option :label="$t('aboutus.7F2D75@Cash_on_delivery')" :value="1" :key="1"></el-option>
                  <el-option :label="$t('aboutus.782FFE@Deferred_payment')" :value="2" :key="2"></el-option>
                  <el-option :label="$t('aboutus.D0F1D6@Set_statement')" :value="3" :key="3"></el-option>
                </el-select>
              </span>
              <!--     取貨方式       -->
              <span>
                {{ $t("product_delivery.F5EA13@product_delivery") }}&nbsp;:&nbsp;
                <el-select style="text-align: center !important;" v-model="product_delivery"
                           @change="changeProductDelivery">
                  <el-option :label="$t('product_delivery.AD58EF@supplier_direct_shipping')" :value="0"
                             :key="0"></el-option>
                  <el-option :label="$t('product_delivery.F5E1A3@in_store_pickup')" :value="1" :key="1"></el-option>
                </el-select>
              </span>
              <!--     送貨地址       -->
              <span>{{ $t("restaurant_store.763B74@address") }}&nbsp;:&nbsp;{{ order.shop.addr }}</span>
              <!--     連絡電話       -->
              <span>{{ $t("orderDetail.2775BD@store_tele") }}&nbsp;:&nbsp;{{ order.shop.telephone }}</span>
              <!--     聯絡人       -->
              <span>{{ $t("restaurant_supplier_addsuppluer.0C63A5@linkman") }}&nbsp;:&nbsp;{{
                  order.shop.linkman
                }}</span>
              <!--     收貨時間       -->
              <span v-if="order.shop.receive_time_start && order.shop.receive_time_end">{{
                  $t("aboutus.D453CE@Time_of_receipt")
                }}&nbsp;:&nbsp;{{ order.shop.receive_time_start + '-' + order.shop.receive_time_end }}</span>
              <span v-else>{{ $t("aboutus.D453CE@Time_of_receipt") }}&nbsp;:&nbsp;</span>
              <!--     送貨時間       -->
              <span>
                {{
                  $t("supplier-setting-DistributionSet.6198C8@delivery_date")
                }}&nbsp;:&nbsp;
                <el-date-picker
                    v-model="order.delivery_date"
                    value-format="timestamp"
                    :picker-options="pickerDisabled"
                    style="width: 200px"
                    type="date"
                    :placeholder="
                    $t('supplier-setting-DistributionSet.F1E87C@Select_date')
                  "
                ></el-date-picker>
              </span>
            </section>
          </el-col>
          <!--     商品選擇       -->
          <el-col :span="8">
            <section v-if="order.sid">
              {{ $t("public.AE2731@select_product") }}&nbsp;:&nbsp;&nbsp;
              <el-select class="mr10" v-model="order.selectGoods" filterable multiple>
                <el-option
                    v-for="item in goodsList"
                    :key="item.gid"
                    :label="item.name"
                    :value="item.gid"
                ></el-option>
              </el-select>
              <el-button style="margin-left: 5px" type="warning" size="small" @click="addGood">
                {{ $t("public.3B45A2@confirm") }}
              </el-button>
            </section>
          </el-col>
        </el-row>
      </div>

      <!--   產品數量   -->
      <div class="goods">
        <div class="ShopCar">
          <div class="main">
            <div
                v-if="!order.goods.length"
                style="font-size: 22px; margin: 20px auto; text-align: center"
            >
              {{ $t("restaurant_supplier_addSupProduct.AAB37F@no_data") }}
            </div>
            <el-table
                border
                v-else
                :data="order.goods"
                v-loading="loading2"
                tooltip-effect="dark"
                style="width: 100%; margin-bottom: 30px"
                :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }">
              <el-table-column prop="picture" :label="$t('restaurant_supplier_products.7E39D8@img')">
                <template slot-scope="scope">
                  <div class="img">
                    <img v-if="scope.row.picture" :src="scope.row.picture" alt/>
                  </div>
                </template>
              </el-table-column>
              <!--       產品名稱       -->
              <el-table-column prop="name" :label="$t('restaurant_inventory.0009CF@product_name')">
              </el-table-column>
              <!--       單位       -->
              <el-table-column prop="unit" :label="$t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit')">
                <template slot-scope="scope">
                  <span v-if="scope.row.unit_id">
                    {{ scope.row.unit_num + ' / ' + scope.row.unit_id }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </template>
              </el-table-column>
              <!--       價格       -->
              <el-table-column
                  show-overflow-tooltip
                  prop="price"
                  :label="$t('shop_cart.86F6A0@unit_price')"
              >
                <template slot-scope="scope">
                  <div>
                    <el-input-number
                        @change="priceChange(scope.$index)"
                        v-model="scope.row.price"
                        size="small"
                        :min="0.01"
                        :label="$t('restaurant_supplier_supplierList.E318B6@desc')"
                    ></el-input-number>
                  </div>
                </template>
              </el-table-column>
              <!--       數量       -->
              <el-table-column
                  show-overflow-tooltip
                  prop="num"
                  :label="$t('shop_cart.D3B150@number')"
              >
                <template slot-scope="scope">
                  <div>
                    <el-input-number
                        v-model="scope.row.num"
                        size="small"
                        @change="numberChange(scope.$index)"
                        :min="0.01"
                        :label="
                        $t('restaurant_supplier_supplierList.E318B6@desc')
                      "
                        style="max-width: 125px"
                    ></el-input-number>
                    <br>
                    <span v-if="product_delivery==0">
                      {{ $t('aboutus.72F5FF@Minimum_order_quantity') + "：" + scope.row.min_quantity }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <!--       總金額       -->
              <el-table-column
                  show-overflow-tooltip
                  prop="total"
                  :label="$t('shop_cart.936345@total_price')">
                <template slot-scope="scope">
                  <span class="unit_price">
                    ${{ scope.row.total.toFixed(2).toLocaleString() }}
                  </span>
                </template>
              </el-table-column>
              <!--       操作       -->
              <el-table-column
                  show-overflow-tooltip
                  prop="opration"
                  :label="$t('supplier_power_grouping.0E5FF1@operation')">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="delGood(scope.$index)">
                    {{ $t("public.1FBB5E@btn_delete") }}
                  </el-button>
                </template>
              </el-table-column>
              <div slot="append" class="calculate">
                <section class="top">
                  <span style="width: 50px">{{ $t("shop-goods.299596@Remarks") }}:</span>
                  <el-input type="textarea" resize="none" :rows="3" v-model="order.remark"
                            style="margin: 1vh 1vw"></el-input>
                </section>
                <div class="top">
                </div>
                <div class="bott">
                  <div class="sum" v-if="product_delivery==0">
                    {{
                      $t("restaurant_supplier_supplierList.5CA70A@min_order")
                    }}：${{
                      delivery && delivery.amount_min > -1 ? this.FormatNumber(delivery.amount_min) : this.shopList.amount_min
                    }}
                  </div>
                  <div class="sum" v-else>
                    {{
                      $t("restaurant_supplier_supplierList.5CA70A@min_order")
                    }}：$0.00
                  </div>
                  <div class="sum">
                    {{ $t("restaurant_supplier_supplierList.2E31F9@fee") }}：
                    <el-input-number
                        @change="deliverChange"
                        v-model="order.deliver_fee"
                        size="small"
                        :min="0"
                        :label="$t('restaurant_supplier_supplierList.2E31F9@fee')"
                    ></el-input-number>
                  </div>
                  <div class="sum" v-if="product_delivery==0">
                    {{
                      $t("restaurant_supplier_supplierList.8FC08B@reduction_conditions")
                    }}：${{
                      delivery && delivery.full_slicing > -1 ? this.FormatNumber(delivery.full_slicing) : this.shopList.full_slicing
                    }}
                  </div>
                  <div class="sum" v-else>
                    {{
                      $t("restaurant_supplier_supplierList.5CA70A@min_order")
                    }}：$0.00
                  </div>
                  <div class="sum">
                    {{ $t("aboutus.09C1E0@Total_amount_of_goods") }}：{{ "$" + order.goodsPrice }}
                  </div>
                  {{ $t("restaurant_buy_genner.E0A3B6@order_price") }}：
                  <span>{{ "$" + order.totalPrice }}</span>
                </div>
              </div>
            </el-table>
            <div style="text-align: center" v-show="order.goods.length">
              <el-button type="success" @click="addOrder">{{ $t('public.DC9752@btn_add') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";

export default {
  data() {
    return {
      //取貨方式
      product_delivery: 0,
      delivery: null,
      loading2: false,
      //產品清單
      goodsList: [],
      //驗證時間
      pickerDisabled: {
        disabledDate: (time) => {
          let date = new Date();
          let str =
              date.getFullYear() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getDate();
          return (
              Date.parse(time) / 1000 <
              Date.parse(str) / 1000
          );
        },
      },
      canShowrestaurantList: [],
      //分店清單
      shopList: [],
      //餐廳清單
      restaurantList: [],
      //顯示新增訂單視窗
      showDialog: false,
      //新訂單
      order: {
        //備註
        remark: "",
        //產品總金額
        goodsPrice: "",
        //運費
        deliver_fee: 0,
        //訂單總金額
        totalPrice: "",
        shop: null,
        //分店id
        sid: "",
        //餐廳id
        rid: "",
        //下單產品
        goods: [],
        //選擇產品
        selectGoods: [],
        //送貨日期
        delivery_date: "",
        //付款方式
        pay_method: 1,
      },
      search: {
        datetime: "",
        order_no: "",
        status_no: this.$route.query.unfinish || "",
        shop_id: "",
        pay_status: this.$route.query.unpay || ""
      },
      oid: "",
      goodsId: "",
      datetime: "",
      supplier: "",
      type: "",
      goodsType: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      supplier_id: "",
      storeOption: [],
      loading: false
    };
  },
  components: {
    pageTop,
    KtButton
  },
  filters: {
    sizeColor: function (value) {
      return `color:${
          value == 1
              ? "green"
              : value == 2
              ? "#f2cd33"
              : value == 3
                  ? "#f24040"
                  : ""
      }`;
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    changeByEvent() {
      this.currentPage = 1
      this.getTableData()
    },
    changeProductDelivery() {
      this.order.deliver_fee = 0
      this.updatePrice()
    },
    FormatNumber(num) {
      var str = num.toString();
      var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    async init() {
      this.getTableData()
      this.getShopSelector()
      await this.restaurantSelector()
    },
    //取得可以顯示的餐廳
    async getCanShowRestaurant() {
      const res = await this.$http.getCanShowRestaurant()
      this.canShowrestaurantList = res.data
    },
    //新增訂單
    addOrder() {
      if (!this.order.delivery_date) {
        this.$message({
          message: this.$t("aboutus.CB7991@Wrong_estimated_delivery_time"),
          type: "warning",
          duration: 2000,
        });
        return;
      }

      this.$http.addOrder({
        goods: JSON.stringify(this.order.goods),
        remark: this.order.remark,
        deliver_fee: this.order.deliver_fee,
        rid: this.order.rid,
        sid: this.order.sid,
        delivery_date: this.delivery_date ? this.deliver_fee / 1000 : "",
        product_delivery: this.product_delivery,
        pay_method: this.order.pay_method,
      }).then(res => {
        if (res.errno == 0) {
          this.order.remark = ""
          this.order.goodsPrice = ""
          this.order.deliver_fee = 0
          this.order.totalPrice = ""
          this.order.shop = null
          this.order.sid = ""
          this.order.rid = ""
          this.order.goods = []
          this.order.selectGoods = []
          this.order.delivery_date = ""
          this.showDialog = false
          this.product_delivery = 0
          this.order.pay_method = 1
          this.init()
        }
      })
    },
    //運費改變
    deliverChange() {
      if (!this.order.deliver_fee) {
        this.order.deliver_fee = 0
      }
      this.updatePrice()
    },
    //更新價格
    updatePrice() {
      let goodsPrice = 0
      let goodsLength = this.order.goods.length
      for (let i = 0; i < goodsLength; i++) {
        this.order.goods[i].total = this.order.goods[i].price * this.order.goods[i].num
        goodsPrice += this.order.goods[i].total
      }
      this.order.goodsPrice = this.FormatNumber(goodsPrice.toFixed(2))
      this.order.totalPrice = this.FormatNumber((goodsPrice + this.order.deliver_fee).toFixed(2))
      this.$forceUpdate()
    },
    //價格改變
    priceChange(index) {
      if (!this.order.goods[index].price) {
        this.order.goods[index].price = 0.01
      }
      this.updatePrice()
    },
    //數值改變
    numberChange(index) {
      if (!this.order.goods[index].num) {
        this.order.goods[index].num = 0.01
      }
      this.updatePrice()
    },
    //刪除產品
    delGood(index) {
      this.order.goods.splice(index, 1)
      this.updatePrice()
    },
    //新增產品
    addGood() {
      //交集
      let intersection = this.order.goods.filter(s => this.order.selectGoods.some(i => s.gid == i))
      intersection.forEach(i => {
        let index = this.order.goods.indexOf(i)
        this.order.goods[index].num += 1.0
      })

      //聯集
      let union = this.order.selectGoods.filter(s => !this.order.goods.some(i => s == i.gid))
      union.forEach(s => {
        let data = this.goodsList.filter(i => {
          return s === i.gid
        })[0]
        if (data) {
          data.num = 1.0
          data.total = (data.num * data.price).toFixed(2)
          this.order.goods.push(data)
        }
      })
      this.updatePrice()
    },
    getCurrentGoodsList() {
      this.$http.getCurrentGoodsList().then(s => {
        this.goodsList = s.data
      })
    },
    //分店改變時
    shopChange() {
      if (this.order.sid) {
        let index = this.shopList.shop.findIndex(s => {
          return s.shop_id === this.order.sid
        })
        this.order.shop = this.shopList.shop[index]
        if (this.order.shop && this.order.shop.supplier_delivery.length) {
          this.delivery = this.order.shop.supplier_delivery[0]
          this.order.deliver_fee = parseFloat(this.delivery.deliver_fee)
        }
        this.getCurrentGoodsList()
      } else {
        this.order.show = null
      }
    },
    //餐廳改變時
    restaurantChange() {
      if (this.order.rid) {
        this.getRestaurantShop()
        this.order.shop = null
        this.delivery = null
        this.order.goods = []
      } else {
        this.shopList = []
        this.order.selectGoods = []
      }
      this.order.sid = ""
    },
    //取得餐廳分店
    getRestaurantShop() {
      this.$http.getRestaurantShop({cid: this.order.rid}).then(res => {
        if (res.data.shop.length) {
          this.shopList = res.data
        } else {
          this.shopList = []
        }
      })
    },
    //取得餐廳
    async restaurantSelector() {
      await this.getCanShowRestaurant()

      this.$http.restaurantSelector().then(res => {
        if (this.canShowrestaurantList.length == res.data.length) {
          this.restaurantList = res.data
        } else {
          this.restaurantList = res.data.filter(s => {
            return this.canShowrestaurantList.some(c => {
              return c == s.company_id
            })
          })
        }
      })
    },
    //获取数据
    getTableData() {
      this.loading = true;
      let params = {
        size: this.pageSize,
        page: this.currentPage,
        status_no: this.search.status_no,
        order_no: this.search.order_no,
        start_time: this.search.datetime[0] / 1000 || "",
        end_time: this.search.datetime[1] / 1000 || "",
        shop_id: this.search.shop_id,
        pay_status: this.search.pay_status
      };
      this.$http.getOrderList(params).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.sum;
      });
    },

    getShopSelector() {
      this.$http.getShopSelector().then(res => {
        this.storeOption = res.data;
      });
    },
    clear() {
      this.getTableData();
    },
    // 去详情页
    rowClick(order_no) {
      this.$router.push({name: "orderDetail", query: {order_no}});
    },
    //时间搜索
    changeDatetime(val) {
      this.search.datetime = val || "";
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.AddOrder {
  .el-select__tags-text {
    word-break: break-all !important;
    max-width: 140px;
    white-space: normal;
  }

  .el-tag {
    height: auto !important;
  }

  .ShopCar {
    margin-top: 30px;

    .main {
      .img {

        img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }
      }

      .calculate {
        width: 100%;

        .top {
          width: 100%;
          padding: pxToper(20) pxToper(30);
          box-sizing: border-box;
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            justify-content: center;
            font-size: pxToper(12);
            font-stretch: normal;
            letter-spacing: 0px;
            color: #666666;

            .dizhi {
              display: flex;
              justify-content: center;
              font-size: pxToper(12);
              font-stretch: normal;
              letter-spacing: 0px;
              color: #666666;

              .time {
                flex-wrap: nowrap;
              }

              .dizhi_area {
                display: flex;
                flex-direction: column;

                .area {
                  line-height: pxToper(22);
                  max-width: pxToper(640);
                }
              }
            }
          }

          .right,
          .sum {
            // width: pxToper(213);

            .min,
            .sum {
              font-size: pxToper(14);
              font-stretch: normal;
              line-height: pxToper(24);
              letter-spacing: 0px;
              color: #333333;
              text-align: right;
            }
          }
        }

        .bott {
          padding: 1vh 1vw;
          font-size: pxToper(14);
          font-weight: bold;
          font-stretch: normal;
          line-height: pxToper(24);
          letter-spacing: 0px;
          color: #333333;
          text-align: right;

          > span {
            font-size: pxToper(36);
            letter-spacing: 0px;
            color: #f24040;
          }
        }
      }

      .buy {
        width: 100%;
        height: pxToper(70);
        background-color: #fff;
        margin-top: pxToper(20);
        border: 1px solid #ebeef5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;

        .aggregate {
          width: pxToper(1170);
          padding: pxToper(20);
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .delete {
            margin-left: pxToper(50);
            cursor: pointer;
            font-size: pxToper(14);
            line-height: pxToper(14);
            font-weight: 500;
            color: #606266;
          }

          .right {
            display: flex;
            align-items: center;
          }

          .selected {
            font-size: pxToper(14);
            line-height: pxToper(14);
            font-weight: 500;
            color: #606266;

            > span {
              font-size: pxToper(24);
              line-height: pxToper(24);
              font-weight: 500;
              letter-spacing: 0px;
              color: #f24040;
            }
          }

          .aggregate-money {
            font-size: pxToper(14);
            line-height: pxToper(14);
            font-weight: 500;
            color: #606266;
            margin-left: pxToper(30);

            > span {
              font-size: pxToper(24);
              line-height: pxToper(24);
              font-weight: 600;
              letter-spacing: 0px;
              color: #f24040;
            }
          }
        }

        > button {
          width: pxToper(150);
          height: 100%;
          background-color: #f24040;
          border: none;
          outline: none;
          font-size: pxToper(20);
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .info {
      white-space: normal !important;
      height: 100%;
      font-size: pxToper(14);
      line-height: pxToper(30);
      color: #666666;
    }

    .unit_price {
      font-weight: bold;
      font-size: pxToper(18);
      line-height: pxToper(30);
      letter-spacing: 0px;
      color: #f24040;
    }

    .el-table--border td {
      border-right: none !important;
    }

    .el-table thead.is-group th {
      background-color: #fff !important;
    }

    .is-group > tr:nth-child(2) > th {
      border-right: none !important;
    }

    .el-table_1_column_2 > div {
      // width: pxToper(185) !important;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }

  .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;

    > span {
      font-size: 14px;
      line-height: 30px;
      color: #666666;
    }
  }

  .delivery {
    padding: 30px 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .integral {
    display: flex;
    flex-direction: column;
    position: relative;

    .update {
      position: absolute;
      top: 3px;
      right: 40%;
      font-size: 12px;
      line-height: 30px;
      color: #ff9906;
      z-index: 99;

      i {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }

  .time,
  .address,
  .roll,
  .integral {
    > span:first-child {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .btn-address {
    width: 120px;
    height: 40px;
    background-image: linear-gradient(90deg, #5c6f86 0%, #404d5e 100%),
    linear-gradient(#3e4b5b, #3e4b5b);
    background-blend-mode: normal, normal;
    border-radius: 5px;
    color: #ffffff;
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .deli-time {
    > span {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .deli-address {
    > span {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .outlet {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    > span {
      margin: 10px 0;
    }
  }
}
</style>
